import './App.css';

function App() {
  return (
    <div className="App flex flex-col justify-between min-h-screen bg-gray-100 items-center">

      {/* Social Media Icons at the Top Middle */}
      <div className="w-full flex justify-center mt-4">
        {/* GitHub */}
        <div className="mx-2">
          <a href="https://github.com/kunaljaykam" target="_blank" rel="noopener noreferrer">
            {/* GitHub Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#181717" viewBox="0 0 24 24">
              <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 
              3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 
              0-.285-.01-1.04-.015-2.04-3.338.726-4.042-1.61-4.042-1.61-.546-1.385-1.333-1.754-1.333-1.754-1.089-.744.084-.729.084-.729 
              1.205.085 1.838 1.236 1.838 1.236 1.07 1.834 2.809 1.304 3.495.997.108-.775.418-1.305.762-1.604-2.665-.305-5.467-1.333-5.467-5.93 
              0-1.31.47-2.38 1.236-3.22-.124-.303-.536-1.524.117-3.176 
              0 0 1.008-.322 3.301 1.23a11.52 11.52 0 013.003-.404c1.02.005 
              2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23 
              .653 1.653.242 2.874.118 3.176.77.84 1.235 
              1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 
              5.921.43.372.823 1.102.823 2.222 0 1.604-.015 2.896-.015 
              3.286 0 .319.218.694.824.576C20.565 22.092 24 17.592 24 
              12.297c0-6.627-5.373-12-12-12"/>
            </svg>
          </a>
        </div>

        {/* X (formerly Twitter) */}
        <div className="mx-2">
          <a href="https://twitter.com/kunaljaykam" target="_blank" rel="noopener noreferrer">
            {/* X Icon */}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 24 24">
              <path d="M23.447 0h-4.61l-6.142 9.373L6.553 
              0H0l9.217 12L0 24h4.608l6.155-9.469L17.435 
              24h6.012l-9.304-12z"/>
            </svg>
          </a>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center flex-grow w-full">
        <h1 className="font-sans text-3xl font-bold text-center text-blue-500 mb-4">
          Kunal Jaykam
        </h1>
        <p className="text-sm text-gray-600 mb-4">Software Engineer</p>

        {/* Sections */}
        <div className="mt-6 px-4 text-center">
          {/* My Work at Company */}
          <div className="mb-6">
            <h2 className="mb-2">SakaiLMS</h2>
   
            <a href="https://www.sakailms.org/sakai-lms-community" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
              Check out!
            </a>
          </div>

          {/* My Photography */}
          <div className="mb-6">
            <h2 className="mb-2">I am also a hobbyist photographer.</h2>

            <a href="https://pinterest.com/kunaljaykam" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
              View my Photography on pinterest
            </a>
          </div>
        </div>
      </div>

      {/* Images for SEO */}
      <div className="hidden">
        <img src="/pictures/kunaljaykam-0.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-1.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-2.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-3.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-4.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-5.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-6.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-8.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-9.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-10.jpeg" alt="Kunal Jaykam" />
        <img src="/pictures/kunaljaykam-11.jpeg" alt="Kunal Jaykam" />
      </div>

      {/* Jaykam Explanation */}
      <div className="w-full md:w-3/5 mt-4 text-xs text-gray-500 mx-auto pb-5">
        <p><strong>What does Jaykam mean?</strong></p>
        <p>
          Jaykam is derived from the names of both my parents. "Jaymani", my mother's name, contributes the "Jay" part, which signifies 'victory' or 'triumph' in Hindi. "Kamlesh", my father's name, lends the "Kam" portion, drawing from the term 'kamna' that means 'wish' or 'desire'. So, in essence, Jaykam represents a 'Triumphant Desire' or a 'Victorious Wish', combining the essence of both their names into one.
        </p>
      </div>
    </div>
  );
}

export default App;
